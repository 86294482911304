export const stringToDate = (value) => {
  if (!value || value.length !== 10) {
    return "";
  }
  const dateStr = value.split("/");
  const dateResult = dateStr[2] + "-" + dateStr[1] + "-" + dateStr[0];
  const dateObj = new Date(dateResult);
  if (dateObj instanceof Date && !isNaN(dateObj)) {
    return dateResult;
  }
  return null;
};

export const stringToDateTime = (value) => {
  if (!value) {
    return "";
  }
  const date = value.split(" ")[0];
  const time = value.split(" ")[1];
  const dateStr = date.split("/");
  let dateResult = dateStr[2] + "-" + dateStr[1] + "-" + dateStr[0];
  dateResult += time ? " " + time : "";
  const dateObj = new Date(dateResult);
  if (dateObj instanceof Date && !isNaN(dateObj)) {
    return dateResult;
  }
  return null;
};

export const dateToString = (value) => {
  if (value && value !== null) {
    let dateStr = undefined;
    if (value.includes("T")) {
      dateStr = value.split("T");
    } else {
      dateStr = value.split(" ");
    }
    if (dateStr.length > 0) {
      const dateParts = dateStr[0].split("-");
      if (dateParts.length === 3) {
        return dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
      }
    }
  }
  return "";
};

export const onlyDate = (value) => {
  if (value && value !== null) {
    const dateParts = value.split(" ");
    if (dateParts.length > 0) {
      return dateParts[0];
    }
  }
  return "";
};

export const dateTimeToString = (value) => {
  if (!value) {
    return "";
  }
  const date = value.split(" ")[0];
  const time = value.split(" ")[1];
  const dateStr = date.split("-");
  const dateResult =
    dateStr[2] + "/" + dateStr[1] + "/" + dateStr[0] + " " + time;
  return dateResult;
};

export const stringToDateObj = (value) => {
  if (!value || value.length !== 10) {
    return null;
  }
  const dateStr = value.split("/");
  const dateResult = dateStr[2] + "-" + dateStr[1] + "-" + dateStr[0];
  return new Date(dateResult);
};

export const getAge = (value) => {
  const date = stringToDateObj(value);
  if (date) {
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  }
  return -1;
};

export const moneyToFloat = (str) => {
  if (str) {
    const number = onlyDigits(str);
    if (number) {
      return parseFloat(number) / 100;
    }
  }
  return 0;
};

export const floatToMoney = (number) => {
  if (!isNaN(number) && number !== null) {
    const result = number.toFixed(2).toString();
    return `R$ ${result.replace(".", ",")}`;
  }
  return "R$ 0,00";
};

export const percentageToFloat = (str) => {
  if (str) {
    const parts = str.split(",");
    if (parts.length === 2) {
      const number = onlyDigits(parts[0]);
      const decimal = onlyDigits(parts[1]);
      if (number && decimal) {
        return parseInt(number) + parseInt(decimal) / 10 ** decimal.length;
      }
    }
  }
  return 0;
};

export const floatToPercentage = (number) => {
  if (!isNaN(number) && number !== null) {
    return number.toFixed(3).toString();
  }
  return "0,000 %";
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const onlyDigits = (str) => {
  if (!str) {
    return "";
  }
  return str.replace(/\D/g, "");
};

export const integerToString = (number) => {
  if (number && !isNaN(number)) {
    return number.toString();
  }
  if (typeof number === "string") {
    return number;
  }
  return "";
};

export const stringToInteger = (str) => {
  if (typeof str === "string") {
    return parseInt(str);
  }
  if (!isNaN(str)) {
    return str;
  }
  return 0;
};

export const removeHTMLtags = (str) => {
  return str.replace(/<\/?[^>]+(>|$)/g, "");
};

export const removeHTMLTagsAndWhiteSpaces = (str) => {
  const newStr = str.replace(/<\/?[^>]+(>|$)/g, "");
  return newStr.replace("&nbsp;", " ");
};

export const removeHTMLStyleAttributes = (str) => {
  return str.replace(/(style=")(.*?)(")/gi, "");
};

export const removeLineBreaks = (str) => {
  return str.replace(/\r?\n|\r/g, "");
};

export const removeTagParagraphs = (str) => {
  return str.replace(/<\/p>/g, "<br/>");
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formattedTime = (time) => {
  let minutes = Math.floor(time / 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  let seconds = time % 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  // The output in MM:SS format
  return `${minutes}:${seconds}`;
};

export const formattedTimeWithHour = (time) => {
  let hours = Math.floor(time / 3600);
  if (hours < 10) {
    hours = `0${hours}`;
  }
  let minutes = Math.floor((time - hours * 3600) / 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  let seconds = time % 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  // The output in MM:SS format
  return `${hours}:${minutes}:${seconds}`;
};

export const booleanToString = (value) => {
  if (value) {
    return "yes";
  }
  return "no";
};

export const stringToBoolean = (str) => {
  if (typeof str === "string") {
    return str === "yes";
  }
  return false;
};

export const truncateString = (str, size) => {
  if (typeof str === "string") {
    return str.length > size ? str.slice(0, size - 1) + "&hellip;" : str;
  }
  return str;
};

export const monthYearAbbrev = (value) => {
  if (value && typeof value === "string") {
    const dateStr = value.split("-");
    const month = parseInt(dateStr[1]);
    let abbrevMoth = "";

    const months = [
      "Jan. ",
      "Fev. ",
      "Mar. ",
      "Abr. ",
      "Maio ",
      "Jun. ",
      "Jul. ",
      "Ago. ",
      "Set. ",
      "Out. ",
      "Nov. ",
      "Dez. ",
    ];

    abbrevMoth = months[month - 1];
    return abbrevMoth + dateStr[0];
  }
  return value;
};

export const toCurrency = (value) => {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const dateToShortFormat = (date) => {
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Maio",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};
