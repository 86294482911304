const CONTRACT_CREATED = 1
const CONTRACT_STATUS_CHANGED = 2
const CONTRACT_NEW_COMMENT = 3
const CONTRACT_NEW_ATTACHMENT = 4
const CONTRACT_FOLLOW_UP = 5
const CUSTOMER_BIRTHDAY = 6
const CUSTOMER_CALL_REMINDER = 7
const CUSTOMER_CHILD_BIRTHDAY = 9
const CUSTOMER_PET_BIRTHDAY = 10

const OWN = 1
const DIRECT_TEAM = 2
const SUPPORT = 3

export default function getNotificationMessage(metadata, notification_type, notification_tag)
{
    var message = ''
    if( notification_type == CONTRACT_NEW_COMMENT || 
        notification_type == CONTRACT_STATUS_CHANGED ||
        notification_type == CONTRACT_NEW_ATTACHMENT
    ){
        if(!notification_tag || notification_tag == DIRECT_TEAM){
            message = 'O contrato do PN <b> ' + metadata.consultant_name + '</b>'
            if(notification_tag == DIRECT_TEAM){
                message += ' da sua equipe direta'
            }
            message += ' do tipo <b>' + metadata.product_name +
                '</b> do cliente <b>' + metadata.customer_name + '</b>'
            if(metadata.support_consultant_name){
                message += ' orientado pelo PN <b>' + metadata.support_consultant_name + '</b>'
            }
        }

        if(notification_tag == OWN){
            message = 'O seu contrato do tipo <b> ' + metadata.product_name + 
                '</b> do cliente <b> ' + metadata.customer_name + '</b>'
            if(metadata.support_consultant_name){
                message += ' orientado pelo PN <b>' + metadata.support_consultant_name + '</b>'
            }
        }

        if(notification_tag == SUPPORT){
            message = 'O contrato que você é o especialista do PN <b> ' + metadata.consultant_name + '</b>' +
                ' do tipo <b>' + metadata.product_name +
                '</b> do cliente <b> ' + metadata.customer_name + '</b>'
        }
    
        if(notification_type == CONTRACT_NEW_COMMENT){ 
            message += ' recebeu o comentário: <b>' + metadata.comment_text + '</b>.'
        }
        if(notification_type == CONTRACT_STATUS_CHANGED){ 
            message += ' mudou para o status  <b>'+ metadata.contract_status_name +'</b>.'
        }
        if(notification_type == CONTRACT_NEW_ATTACHMENT){ 
            message += ' recebeu um anexo.'
        }
    }

    if(notification_type == CUSTOMER_BIRTHDAY){
        message = 'Hoje é o aniversário de <b>' + metadata.customer_name + '</b>!'
    }

    if(notification_type == CUSTOMER_CHILD_BIRTHDAY){
        message = 'Hoje é o aniversário de <b>' + metadata.child_name + '</b>, filho(a) de <b>' + metadata.customer_name + '</b>!'
    }

    if(notification_type == CUSTOMER_PET_BIRTHDAY){
        message = 'Hoje é o aniversário de <b>' + metadata.pet_name + '</b>, pet de <b>' + metadata.customer_name + '</b>!'
    }

    if(notification_type == CUSTOMER_CALL_REMINDER){
        message = 'Você possui um lembrete hoje às <b>' + metadata.call_reminder_hour + '</b>' + 
           ' para o cliente <b>'+ metadata.customer_name + '</b>!'
    }

    return message
}