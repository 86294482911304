<template>
  <div>
    <notification-sidebar />
    <h1>
      <span v-if="status === 'notifications'">Notificações</span>
      <span v-else-if="status === 'pendencies'">Pendências</span>
      <span v-else-if="status === 'solved'">Resolvidas</span>
    </h1>
    <div v-if="!loading" class="justify-content-right m-2">
      <b-dropdown
        v-if="selectedNotifications.length > 0"
        class="mx-1"
        variant="outline-secondary"
        size="sm"
        right
        text="Mover para"
      >
        <b-dropdown-item
          v-if="status !== 'notifications'"
          @click="updateStatus('notifications')"
        >
          Notificações
        </b-dropdown-item>
        <b-dropdown-item
          v-if="status !== 'pendencies'"
          @click="updateStatus('pendencies')"
        >
          Pendências
        </b-dropdown-item>
        <b-dropdown-item
          v-if="status !== 'solved'"
          @click="updateStatus('solved')"
        >
          Resolvidas
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        v-if="selectedNotifications.length > 0"
        variant="outline-success"
        size="sm"
        @click="readSelectedNotifications"
      >
        <span class="text-nowrap">Marcar selecionadas como lida</span>
      </b-button>
      &nbsp;&nbsp;
      <b-button
        v-if="items.length > 0 && canReadAll"
        size="sm"
        variant="outline-primary"
        @click="readAll"
      >
        <span class="text-nowrap">Marcar todas como lida</span>
      </b-button>
    </div>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <b-card no-body class="mb-0 m-2">
        <draggable
          v-model="items"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="notification in items"
            :key="notification.id"
            class="todo-item"
            @click="read(notification)"
          >
            <div class="todo-title-wrapper justify-content-spacebetween">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    v-model="selectedNotifications"
                    :value="notification.id"
                    @click.native.stop
                  />
                  <span
                    class="todo-title"
                    :class="{
                      'text-muted': notification.read,
                      'text-primary': !notification.read,
                    }"
                    v-html="truncateString(getNotificationMessage(notification.metadata, notification.type, notification.tag_id), 200)"
                  >
                  </span>
                </div>
              </div>
              <div class="mr-1">
                <b-badge variant="purple">
                  {{ notification.notification_type.type }}
                </b-badge>
                <br />
                <b-badge v-if="notification.notification_tag" variant="blue">
                  {{ notification.notification_tag.tag }}
                </b-badge>
              </div>
              <div>
                <small class="text-muted mr-1">
                  {{ notification.formatted_date }} <br />
                  alterado por: <br />
                  <span v-if="notification.user">{{
                    notification.user.name
                  }}</span>
                  <span v-else>Sistema</span>
                </small>
              </div>
            </div>
          </li>
        </draggable>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
                {{ totalItems }} itens</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                :value="currentPage"
                :total-rows="totalItems"
                :per-page="itemsPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="get"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center">
        Não foram encontradas notificações
      </span>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BFormCheckbox,
  BBadge,
  BButton,
  BRow,
  BCol,
  BPagination,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import { avatarText } from "@core/utils/filter";
import { truncateString } from "@/helpers/converters";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import NotificationSidebar from "../components/NotificationSidebar";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  APP_BACKOFFICE_ROLE_ID,
  APP_FINANCIAL_ROLE_ID,
  APP_ADMIN_ROLE_ID,
} from "@/constants/auth";
import getNotificationMessage from "@/libs/notification"

export default {
  components: {
    BCard,
    BSpinner,
    BBadge,
    BButton,
    draggable,
    BCol,
    BRow,
    BPagination,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    NotificationSidebar,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "date", label: "Criado" },
        { key: "comment", label: "Comentário" },
        { key: "user", label: "Responsável" },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      selectedNotifications: [],
      itemsPerPage: 15,
    };
  },
  computed: {
    ...mapGetters({
      notifications: types.NOTIFICATION_LIST,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      unreadNotifications: types.UNREAD_NOTIFICATIONS,
      notificationSibebar: types.NOTIFICATION_SIDEBAR,
      user: accountTypes.USER,
    }),
    items: function () {
      return this.notifications?.data || [];
    },
    totalItems: function () {
      return this.notifications?.total || 0;
    },
    pageItemsFrom: function () {
      return this.notifications?.from || 0;
    },
    pageItemsTo: function () {
      return this.notifications?.to || 0;
    },
    canReadAll: function () {
      return (
        this.user?.user_role_id != APP_BACKOFFICE_ROLE_ID &&
        this.user?.user_role_id != APP_FINANCIAL_ROLE_ID &&
        this.user?.user_role_id != APP_ADMIN_ROLE_ID
      );
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.get(this.currentPage);
  },
  methods: {
    ...mapActions({
      getNotifications: types.GET_NOTIFICATION_LIST,
      getNotificationsCounter: types.GET_NOTIFICATIONS_COUNTER,
      openNotificationSidebar: types.OPEN_NOTIFICATION_SIDEBAR,
      readAllNotifications: types.READ_ALL_NOTIFICATIONS,
      readNotifications: types.READ_NOTIFICATIONS,
      updateNotificationStatus: types.UPDATE_NOTIFICATION_STATUS,
    }),
    getNotificationMessage,
    truncateString,
    get(currentPage) {
      this.loading = true;
      this.currentPage = currentPage;
      this.selectedNotifications = [];
      this.getNotificationsCounter().catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
      this.getNotifications({
        status: this.status,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage) ? currentPage : 1,
      })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    read(item) {
      this.selectedNotifications.push(item.id);
      this.openNotificationSidebar({
        id: item.id,
        title: item.notification_type.type,
        tag: item.tag_id,
        type: item.type,
        metadata: item.metadata,
        markAsRead: false,
      });
    },
    readAll() {
      this.loading = true;
      this.$swal({
        title: "Confirmação",
        text: `Deseja marcar todas as notificações como lidas?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Marcar",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      })
        .then((result) => {
          if (result.value) {
            this.readAllNotifications({ status: this.status })
              .then(() => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Sucesso",
                    text: "Todas as notificações foram marcadas como lida!",
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.get(1);
              })
              .catch(() => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    readSelectedNotifications() {
      this.readNotifications({
        selectedNotifications: this.selectedNotifications,
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Todas as notificações foram marcadas como lida!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.get(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao marcar as notificações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateStatus(status) {
      this.updateNotificationStatus({
        selectedNotifications: this.selectedNotifications,
        status,
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Todas as notificações foram movidas!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.get(this.currentPage);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao mover as notificações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    status: function () {
      this.get(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
